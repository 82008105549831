import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  HalfWidthBlock,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Top from "../../../images/reference/ldf/top.jpg"
import Logo from "../../../images/reference/ldf/logoldf.svg"
import Header from "../../../images/reference/ldf/header-marketing.png"
import Sideimg from "../../../images/reference/ldf/screen-1.png"

class LDFMendelu extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(10,75,54,0.8),rgba(0,0,0,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Reference na nový web pro LDF MENDELU | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          heading="Reference na nový web pro LDF MENDELU"
          odstavec="2013"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Lesnická a dřevařská fakulta je jednou z pěti fakult Mendelovy univerzity v Brně a zároveň její vlajkovou lodí na poli výzkumu a vývoje krajiny, ekologie a zpracování dřeva. Každý rok přijímá 700 nových uchazečů."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p>
                Nahradit nevyhovující a špatně strukturované webové stránky za
                přehledné a responzivní řešení, které podpoří výkon online
                kampaně na propagaci velkoobchodu v Praze.
              </p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <ul>
                <li>krátký průzkum trhu a návrh wireframů</li>
                <li>vytvoření vlastní grafiky</li>
                <li>
                  zpracování do HTML kódu s využitím moderní vektorové grafiky
                </li>
                <li>nasazení na redakční systém Drupal</li>
                <li>usnadnění výpisu volných pozic</li>
                <li>Facebook kampaň na propagaci pražského velkoobchodu</li>
              </ul>
            </PaddingWrapper>
          </HalfWidthBlock>

          <BlockImageTextHalf
            className="d-md-none"
            image={Sideimg}
            alt={"ALT"}
            right
            hideImageOnSmall
          >
            <TitleH2>Spolupráce</TitleH2>
            <p>
              Celý projekt se nám podařilo dotáhnout do zdárného konce v
              rekordně krátkém čase, a to i díky výborné komunikaci s klientem.
              Dokázali jsme nový web úspěšně zbavit zastaralého obsahu a
              usnadnit zákazníkům vyhledávání nezbytných informací. Nyní jsou
              proto kontakty, sortiment i rozvozové trasy dostupné zcela
              intuitivně.
            </p>
            <p>
              Po dokončení zakázky jsme navíc pracovali na usnadnění výpisu
              aktuálních pracovních nabídek a úspěšné online kampani na podporu
              pražského velkoobchodu.
            </p>
          </BlockImageTextHalf>
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default LDFMendelu
